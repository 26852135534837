import { React, useEffect } from 'react'
import MetaTags from '../components/MetaTags';

function Floppy() {
    
    useEffect(() => {
        //loadChatbot();
      }, [])

    return (
        <>
            <MetaTags
                title='Ne ratez pas votre prochaine innovation'
                description="Découvrez notre assistant virtuel (IA) pour vous aider à innover."
                image='assets/img/favicon.png'
                name='Jelam'
            />
            <div className='stg-container'>
                {/* Section: Page Title */}
                <section className="backlight-bottom">
                    <div className="stg-row stg-bottom-gap-l">
                        <div className="stg-col-8 stg-offset-2">
                            <div className="align-center">
                                <h1 className="bringer-page-title" data-appear="fade-up" data-unload="fade-up">Testez notre AI (Assistant Innovation) ! </h1>
                            </div>
                        </div>
                    </div>
                    <div className="stg-row">

                    <agent-standard style= {{width: "100%", height: "600px"  }}></agent-standard>
                    </div>

                </section>

            </div>
        </>
    )

    function loadChatbot() {
                // JavaScript: 
                const agentInitScript = document.createElement("script"); 
                agentInitScript.type = "module"; 
                agentInitScript.innerHTML = `import Agent from 'https://cdn.jsdelivr.net/npm/@agent-embed/js@latest/dist/web.js' 
                Agent.initStandard({   agentName: "Assistant OpenAI-17e3b",   apiHost: "https://app.predictabledialogs.com/web/incoming",   initialPrompt: "Hi", }); `; 
                document.body.append(agentInitScript); // Place the below component in your html 
      }
}

export default Floppy