import React from 'react'

function Footer() {
  return (
    <div>
    {/* Footer */}
        <footer id="bringer-footer" data-appear="fade-up" data-unload="fade-down">
            {/* Footer Widgets Area */}
            <div className="bringer-footer-widgets">
                <div className="stg-container">
                    <div className="stg-row" data-stagger-appear="fade-left" data-stagger-delay="100">
                        <div className="stg-col-5 stg-tp-col-12 stg-tp-bottom-gap-l">
                            <div className="bringer-info-widget">
                                <a href="./" className="bringer-logo footer-logo">
                                    <img src="assets/img/logo.png" alt="Jelam." width="88" height="24" />
                                </a>
                                <div className="bringer-info-description">Nous croyons que la créativité est une force puissante capable de changer le monde. Chaque jour nous vous aidons à concrétiser vos idées en innovations profitables.</div>
                                <span className="bringer-label">Suivez-nous:</span>
                                <ul className="bringer-socials-list" data-stagger-appear="fade-up" data-stagger-delay="75">
                                    <li>
                                        <a href="https://www.linkedin.com/company/jelamfr" target="_blank" rel="noreferrer" className="bringer-socials-linkedin">
                                            <i></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="stg-col-2 stg-tp-col-4 stg-m-col-4">
                            <div className="bringer-widget stg-bottom-gap">
                                <h6>Nouveautés</h6>
                                <div className="jelam-info-widget">
                                    <ul>
                                        <li><a href='/floppy'>Testez notre assistant</a></li>
                                        <li><a href="https://www.latelierit.fr" target="_blank" rel="noreferrer">Nos formations</a></li>
                                    </ul>
                                </div>
                            </div>{/*  .bringer-widget */}
                            <div className="bringer-widget">
                                <h6>Nos domaines</h6>
                                <div className="jelam-info-widget">
                                    <ul>
                                        <li>Industrie 4.0</li>
                                        <li>Smart City</li>
                                        <li>Sport & Loisirs</li>
                                    </ul>
                                </div>
                            </div>{/*  .bringer-widget */}
                        </div>
                        <div className="stg-col-2 stg-offset-1 stg-tp-col-4 stg-m-col-4">
                            <div className="bringer-widget">
                                <h6>Vous êtes</h6>
                                <div className="bringer-menu-widget">
                                    <ul>
                                        <li><a href="/startup">Une Startup</a></li>
                                        <li><a href="/company">Une PME ou ETI</a></li>
                                        <li><a href="/founder">Un fondateur</a></li>
                                    </ul>
                                </div>
                            </div>{/*  .bringer-widget */}
                        </div>
                        <div className="stg-col-2 stg-tp-col-4 stg-m-col-4">
                            <div className="bringer-widget">
                                <h6>Légales</h6>
                                <div className="bringer-menu-widget">
                                    <ul>
                                        <li><a href="/cgu">CGU</a></li>
                                        <li><a href="/privacy">Politique de confidentialité</a></li>
                                    </ul>
                                </div>
                            </div>{/*  .bringer-widget */}
                        </div>
                    </div>{/* .stg-row */}
                </div>{/* .stg-container */}
            </div>{/* .bringer-footer-widgets */}

            {/* Footer Copyright Line */}
            <div className="bringer-footer-line stg-container">
                <div className="align-center">
                    Copyright &copy; 2024. Tous droits réservés.
                </div>
            </div>{/* bringer-footer-line */}
        </footer>    
    </div>
  )
}

export default Footer